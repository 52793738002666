$main_clr: #ECF6D4;
$main_dark_clr: #ACD92C;
$main_clr_dark: #3D9DDE;
$main_clr_light: #DFF5FE;
$primary_clr: #FFFFFF;
$separator: rgba(0, 0, 0, 0.12);
$body_bg: rgb(248, 249, 250);
$clr_1: #1D2649;
$clr_2: rgb(52, 71, 103);
$clr_3: rgb(131, 146, 171);
$clr_4: rgb(103, 116, 142);
$clr_5: rgba(0, 0, 0, 0.05);
$alarm_clr: #EC4F4F;
//$alarm_clr_light: #fff4f3;
//$success_clr_light: #F4FFF7;
