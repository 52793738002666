@import "color";
@import "font";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  font-size: 16px;
  background: $body_bg;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html {
  min-width: 320px;
  scroll-behavior: smooth;
}


input,
button,
textarea {
  font-family: inherit;
}

a,
button {
  cursor: pointer;
}


a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
  cursor: pointer;
}

img {
  max-width: 100%;
  max-height: 100%;
}


a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
input:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus {
  outline: 0 !important;
  outline-color: transparent !important;
  outline-style: none !important;
}

.main-border-radius {
  border-radius: 0.625rem;
}

.secondary-border-radius {
  border-radius: 0.31rem;
}

.transition {
  transition: all 0.2s ease;
}

@mixin main-box-shadow {
  box-shadow: rgb(0, 0, 0, 0.09) 0 0.1875rem 0.3125rem -0.0625rem, rgb(0, 0, 0, 0.07) 0rem 0.125rem 0.3125rem -0.0625rem;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.icon_wh-20 {
  width: 20px;
  height: 20px;
}

.icon_wh-16 {
  width: 16px;
  height: 16px;
}

.icon_wh-30 {
  width: 30px;
  height: 30px;
}

.icon_wh-32 {
  width: 32px;
  height: 32px;
}

.icon_wh-60 {
  width: 60px;
  height: 60px;
}

.icon_wh-74 {
  width: 74px;
  height: 74px;
}

.button__container {
  display: flex;
  justify-content: center;
}

.button__container_g-1 {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.pointer {
  cursor: pointer;
}

.title__primary {
  @include font(1.5rem, 400, $clr_1);
}

.title__secondary {
  @include font(1rem, 600, $clr_2);
}

.title_underline {
  border-bottom: 1px solid;
}

textarea,
input {
  cursor: pointer;
  @include font(0.875rem, 400, $clr_1);
  padding: 0.5rem 0.75rem;
  border: 1px solid $separator;
  @extend .secondary-border-radius;
  height: 40px;
  min-width: 220px;
  background: $primary_clr;

  &::placeholder {
    opacity: 0.5;
  }

  &:focus, &:active {
    box-shadow: $main_clr 0 0 0 0.125rem;
  }

  &:disabled {
    background: $clr_5;
  }

  &:hover {
    border-color: $main_dark_clr;
  }
}

input[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

input[type=date] {
  position: relative;
  z-index: 1;

  &:after {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    background-image: url("../assets/icon/ic_date.svg");
    background-repeat: no-repeat;
    background-size: cover;
    right: 10px;
    top: calc(50% - 10px);
    z-index: -1;
  }
}

textarea {
  min-height: 100px;
  resize: vertical;
}

.input_eye {
  position: relative;

  .form__input {
    padding-right: 37px;
  }

  .eye {
    cursor: pointer;
    position: absolute;
    right: 0.75rem;
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    @extend .transition;
  }

  .eye_open {
    background-image: url("../assets/icon/ic_eye.svg");
  }

  .eye_close {
    background-image: url("../assets/icon/ic_eye-close.svg");
  }
}

.button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $main_dark_clr;
  @include font(0.75rem, 700, $primary_clr);
  text-transform: uppercase;
  padding: 0.75rem 1.5rem;
  @extend .secondary-border-radius;
  @include main-box-shadow;
  transition: transform 0.2s ease;
  flex-shrink: 0;

  &:hover {
    transform: scale(1.02);
  }

  &:disabled {
    opacity: 0.6;
    cursor: no-drop;

    &:hover {
      transform: scale(1);
    }
  }
}

.button__round {
  border-radius: 50%;
  padding: 0;
  width: 40px;
  height: 40px;
}

.button__round_light {
  background: $primary_clr;
  @include main-box-shadow;
}

.button__clear {
  cursor: pointer;
  display: flex;
  align-items: center;
  @include font(0.875rem, 700, $alarm_clr);

  .button__clear-title {
    margin-left: 5px;
  }
}

.button__cell {
  height: 30px;
  padding: 0.75rem;
}

//.button__clear_container {
//  padding: 1rem 0;
//  display: flex;
//  justify-content: flex-end;
//  width: 100%;
//}

//.button__secondary {
//  background: $clr_1;
//  padding: 10px 20px;
//  text-transform: none;
//
//  img {
//    width: 14px;
//    height: 14px;
//    margin-right: 6px;
//  }
//}

/* TABS */
.tabs {
  display: flex;
  height: 45px;
  background-color: $body_bg;
  @extend .secondary-border-radius;
  padding: 0.25rem;
  max-width: fit-content;
  @include main-box-shadow;
  margin-bottom: 16px;
  overflow-x: auto;
  flex-shrink: 0;
}

.tabs__container {
  display: flex;
  align-items: center;
}

.tabs__item {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  @extend .transition;
  background: transparent;
  padding: 0.5rem 2rem;
  @extend .secondary-border-radius;
  @include font(0.8rem, 500, $clr_1);

  &:hover {
    @include main-box-shadow;
    background: $primary_clr;
  }
}

.tabs__item_active {
  @include main-box-shadow;
  background: $primary_clr;
}

.select {
  height: 40px;

  .select__control {
    height: 40px;
    cursor: pointer;

    &:hover {
      border-color: $main_dark_clr;
    }

    .select__value-container {
      padding: 0 8px;
      height: 40px;
      grid-template-rows: 38px;

      .select__input-container {
        .select__input {
          &:focus, &:active {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.select__dropdown-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.select-invalid {
  .select__control {
    border-color: $alarm_clr;

    &:hover {
      border-color: $alarm_clr;
    }
  }
}

.dropdown__body {
  position: absolute;
  top: 100%;
  right: 0;
  background: $primary_clr;
  max-height: 0;
  min-width: 100%;
  overflow: hidden;
  @extend .transition;
  display: flex;
  flex-direction: column;
  @include main-box-shadow;
  z-index: 2;
}

//.switch__container {
//  display: flex;
//  align-items: center;
//
//  .switch__label {
//    margin-left: 0.5rem;
//    @include font(0.875rem, 400, $clr_1);
//    line-height: 20px;
//  }
//}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $separator;
    transition: .4s;
    border-radius: 34px;

    &::before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 1px;
      bottom: 1px;
      background-color: $primary_clr;
      @include main-box-shadow;
      transition: .4s;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: $main_dark_clr;
  }

  input:checked + .slider:before {
    transform: translateX(20px);
  }
}

.radio__container {
  display: flex;
  align-items: center;
  position: relative;

  .radio {
    position: absolute;
    right: 0;
    appearance: none;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    background: transparent;
    border: 2px solid $separator;
    outline: 0;
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0;
  }

  .radio:checked {
    border-color: $main_dark_clr;
    background-image: url("../assets/icon/ic_shape.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
}

.radio__label {
  display: flex;
  align-items: center;
}

.main-checkbox {
  cursor: pointer;
  position: absolute;
  min-width: 24px;
  height: 24px;
  z-index: -1;
  opacity: 0;

  & + .main-checkbox__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    user-select: none;
    @include font(0.875rem, 400, $clr_2);
  }

  & + .main-checkbox__label::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid $separator;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }

  &:checked + .main-checkbox__label::before {
    border-color: $main_dark_clr;
    background-color: $main_dark_clr;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }

  &:not(:disabled):not(:checked) + .main-checkbox__label:hover::before {
    border-color: $main_clr;
  }

  &:not(:disabled):active + .main-checkbox__label::before {
    background-color: $main_clr;
    border-color: $main_clr;
  }

  &:focus + .main-checkbox__label::before {
    box-shadow: 0 0 0 0.2rem $main_clr;
  }

  &:focus:not(:checked) + .main-checkbox__label::before {
    border-color: $main_clr;
  }

  &:disabled + .main-checkbox__label::before {
    background-color: $separator;
  }
}

//.main-checkbox__body {
//  display: flex;
//  align-items: center;
//
//  .main-checkbox__container {
//    display: flex;
//    align-items: center;
//    margin-right: 20px;
//
//    &:last-child {
//      margin-left: 10px;
//    }
//
//    .main-checkbox__label {
//      @include font(0.75rem, 500, $clr_3);
//    }
//  }
//}

.clear__container {
  display: flex;
  align-items: center;
}

.country__container {
  display: flex;
  align-items: center;
}

.copyright {
  @include font(1rem, 400, $clr_1);
  opacity: 0.6;
}

::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #F1F1F1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  height: 30px;
  border-radius: 4px;
  background: $main_dark_clr;
}

::-webkit-scrollbar-thumb:hover {
  background: $main_dark_clr;
}

::-webkit-scrollbar-thumb:active {
  background: $main_dark_clr;
}

////////////////////
.icon_wh-1-25 {
  width: 1.25rem;
  height: 1.25rem;
}

.icon_wh-24 {
  width: 24px;
  height: 24px;
}

.icon_wh-14 {
  width: 14px;
  height: 14px;
}

.icon-country {
  background-size: 100%;
  background-repeat: no-repeat;
  margin-right: 0.5rem;
}

.icon-country-BY {
  background-image: url("../assets/icon/icon-country-BY.svg");
}

.icon-country-RU {
  background-image: url("../assets/icon/icon-country-RU.svg");
}

.icon-country-LT {
  background-image: url("../assets/icon/icon-country-LT.svg");
}

.card-icon {
  display: inline-block;
  width: 40px;
  height: 30px;
  margin-right: 0.5rem;
  background-repeat: no-repeat;
  background-size: 100%;
}

.ic_payment_card_logo_default {
  background-image: url('../assets/icon/ic_payment_card_logo_default.svg');
}

.ic_payment_card_logo_visa {
  background-image: url('../assets/icon/ic_payment_card_logo_visa.svg');
}

.ic_payment_card_logo_master-card {
  background-image: url('../assets/icon/ic_payment_card_logo_master-card.svg');
}

.ic_payment_card_logo_maestro {
  background-image: url('../assets/icon/ic_payment_card_logo_maestro.svg');
}

.ic_payment_card_logo_mir {
  background-image: url('../assets/icon/ic_payment_card_logo_mir.svg');
}

.ic_payment_card_logo_other {
  background-image: url('../assets/icon/ic_payment_card_logo_other.svg');
}

////////////////////

/* DASHBOARD */
.dashboard__container {
  overflow: auto;
}

.dashboard__row {
  display: flex;
  position: relative;
  width: fit-content;
  margin: 24px 0;
}

.dashboard__small-box_container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.dashboard__small-box_item {
  @extend .secondary-border-radius;
  background: $primary_clr;
  padding: 1rem;
  display: flex;
  gap: 2rem;
  align-items: flex-start;

  &:nth-child(1) {
    .dashboard__small-box-icon {
      background: linear-gradient(310deg, rgb(17, 113, 239), rgb(17, 205, 239));

      &:after {
        background: url("../assets/icon/ic_coins.svg") no-repeat;
        background-size: 100%;
      }
    }
  }

  &:nth-child(2) {
    .dashboard__small-box-icon {
      background: linear-gradient(310deg, rgb(245, 54, 92), rgb(245, 96, 54));

      &:after {
        background: url("../assets/icon/ic_calendar.svg") no-repeat;
        background-size: 100%;
      }
    }
  }
}

.dashboard__small-box-icon {
  width: 3rem;
  height: 3rem;
  position: relative;
  flex-shrink: 0;
  border-radius: 50%;

  &:after {
    position: absolute;
    content: '';
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    width: 24px;
    height: 24px;
  }
}

.dashboard__small-box-icon_add {
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: -15px;
    right: -15px;
    width: 35px;
    height: 35px;
    background-image: url('../assets/icon/plus.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
    background-color: $main_dark_clr;
    border-radius: 50%;
    @include main-box-shadow;
  }
}

.dashboard__small-box_body {
  display: flex;
  flex-direction: column;
}

.dashboard__small-box-title {
  text-transform: uppercase;
  @include font(0.875rem, 600, $clr_4);
}

.dashboard__small-box-description {
  @include font(1.25rem, 700, $clr_2);
}

.dashboard__item_donut-container {
  display: flex;
  flex-direction: column;
  @extend .secondary-border-radius;
  padding: 1rem;
  background: $primary_clr;
}

.dashboard__item_donut-body {
  padding: 0.5rem 0;
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.donut__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem;
}

.dashboard__country-container {
  margin-bottom: 0.5rem;
}

.dashboard__item_donut-legends {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.legends__item {
  display: flex;
  align-items: center;
}

.legends__title {
  @include font(0.875rem, 400, $clr_1);
}

.legends__square {
  display: flex;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  border-radius: 2px;
  margin-right: 0.5rem;
}

.legends__item-count {
  margin-left: 0.5rem;
  @include font(0.875rem, 500, $clr_1);
}

.dashboard__item_donut-legends_secondary {
  flex-direction: column;
}

.legends__item_secondary {
  justify-content: space-between;
  min-width: 200px;
}

.legends__item-body {
  display: flex;
  align-items: center;
}

.add__block {
  position: relative;
  transition: width 0.5s ease, padding-left 0.5s ease, padding-right 0.5s ease;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.add__block_hidden {
  width: 0;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}


.add__block_visible {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow-y: auto;
}

/* FORM */
.form {
  display: flex;
  flex-direction: column;
}

.form__card-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form__card-item {
  cursor: pointer;
  padding: 1rem;
  border: 1px solid $separator;
  @extend .secondary-border-radius;
  @include font(1rem, 400, $clr_2);
}

.form__card-item_active {
  border-color: $main_dark_clr;

  .ic_payment_card_logo_default {
    background-image: url('../assets/icon/ic_payment_card_logo_default_active.svg');
  }

  .ic_payment_card_logo_visa {
    background-image: url('../assets/icon/ic_payment_card_logo_visa_active.svg');
  }

  .ic_payment_card_logo_master-card {
    background-image: url('../assets/icon/ic_payment_card_logo_master-card_active.svg');
  }

  .ic_payment_card_logo_maestro {
    background-image: url('../assets/icon/ic_payment_card_logo_maestro_active.svg');
  }

  .ic_payment_card_logo_mir {
    background-image: url('../assets/icon/ic_payment_card_logo_mir_active.svg');
  }

  .ic_payment_card_logo_other {
    background-image: url('../assets/icon/ic_payment_card_logo_other_active.svg');
  }
}

.form-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.5rem 0;
}

.form-line-select {
  width: 120px;
}

.form__title {
  @include font(0.75rem, 700, $clr_1);
  opacity: 0.6;
  text-transform: uppercase;
  padding: 2rem 0 1rem 0;
}

.form__input-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form__input-item {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.form__input-item-with-label {
  gap: 0.31rem;
}

.form__input-label {
  @include font(0.75rem, 700, $clr_2);
}

.form__error-container {
}

.form__error-text {
  @include font(0.875rem, 400, $alarm_clr);
}

.small-text-right {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  @include font(0.875rem, 500, $clr_1);
  opacity: 0.9;
  padding-bottom: 0.5rem;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.login__container {
  min-width: 320px;
  background: $primary_clr;
  @extend .main-border-radius;
  @include main-box-shadow;
}

.login__header {
  background: $main_clr;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border-radius: 0.625rem 0.625rem 0 0;
}

.login__logo {
  width: 240px;
  height: 40px;
}

.login__body {
  padding: 1.5rem;
}

.login__message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.login__message-img {
  width: 100px;
  height: 100px;
}

.login__message-text {
  @include font(0.875rem, 400, $clr_1);
}

.login__btn-container {
}

.switch__notice {
}

.switch__notice-link {
  @include font(0.875rem, 700, $clr_1);
  opacity: 0.6;
}

.login__footer {
  border-top: 1px solid $separator;
  padding: 1.5rem;
  cursor: pointer;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.login__footer-text {
  @include font(0.875rem, 400, $clr_1);
}

.login__footer-link {
  padding-left: 5px;
  @include font(0.875rem, 700, $clr_1);
}

.recovery__container {
  max-width: 500px;
}

.recovery__form {
  padding: 1.5rem 0;
}

.language__container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  height: 60px;

  &:hover {
    .dropdown__body {
      max-height: 250px;
      padding: 0.625rem;
    }
  }
}

.flag {
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0.625rem;
}

.EN {
  background-image: url("../assets/icon/flag-united-states.svg");
}

.RU {
  background-image: url("../assets/icon/flag-russia.svg");
}

.wrapper {
  display: flex;
  margin: 0 auto;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.navigation {
  display: flex;
  flex-direction: column;
  position: fixed;
  background: $primary_clr;
  padding: 0.625rem;
  @extend .main-border-radius;
  flex-shrink: 0;
  transition: all 0.5s ease;
  width: 244px;
  z-index: 100;
  max-height: calc(100% - 40px);
}

.logo__container {
  border-bottom: 1px solid $separator;
  padding: 0.625rem 1rem;
  overflow: hidden;
  flex-shrink: 0;

  .logo {
    width: 192px;
    height: 32px;
    background-size: 100%;
    background-image: url("../assets/icon/logo.svg");
    background-repeat: no-repeat;
  }
}

.navigation__menu {
  padding-top: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
}

.navigation__item {
  width: 100%;
  padding: 0.625rem 1rem;
  @extend .secondary-border-radius;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  white-space: nowrap;

  &:hover {
    transition: background-color 0.5s ease;
    background: $main_clr_light;
    font-weight: 500;
  }
}

.active {
  background: $main_clr_light;
  font-weight: 500;
}

.navigation_desktop {
  display: flex;
}

.navigation_mobile {
  display: none;
}

.navigation__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  @include font(0.75rem, 700, $clr_1);
  opacity: 0.6;
  padding: 0.625rem 1rem;
}

.navigation__item-img {
}

.navigation__item-text {
  @include font(0.8rem, 400, $clr_1);
  white-space: nowrap;
}

.navigation__hidden {
  width: 90px;

  .logo__container {
    .logo {
      width: 32px;
      height: 32px;
      background-image: url("../assets/icon/logo-small.svg");
    }
  }

  .navigation__item-text {
    display: none;
  }
}

.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin-left: 268px;
  transition: all 0.5s ease;

  &:before {
    position: absolute;
    height: 30%;
    content: '';
    left: -100%;
    right: -100%;
    top: -1.25rem;
    background: $main_clr;
    z-index: -1;
  }
}

.main__container {
  display: flex;
  position: relative;
  overflow: hidden;
  @extend .main-border-radius;
  height: 100%;
}

.header {
  padding: 0.5rem 0;
  min-height: 4.6875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-menu {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.menu {
  transition: all 0.1s ease;
}

.menu-open {
  background: url("../assets/icon/menu.svg") 100% no-repeat;
}

.menu-hidden {
  background: url("../assets/icon/menu_hidden.svg") 100% no-repeat;
}

.icon-menu-mobile {
  display: none;
}

.header__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.875rem;
}

.user__info {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;

  &:hover {
    .dropdown__body {
      max-height: 500px;
      padding: 0.625rem;
    }
  }

  .user__dropdown {
  }
}

.log-out {
  margin-top: 1.25rem;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: -0.625rem;
    left: 0;
    width: 100%;
    height: 1px;
    background: $separator;
  }
}

.user__img {
  @extend .secondary-border-radius;
  background: $primary_clr;
}

.user__description {
}

.user__name {
  @include font(1.25rem, 600, $clr_1);
}

.user__balance {
  @include font(0.875rem, 500, $clr_1);
}

.user__dropdown-item {
  padding: 0.625rem;
  @extend .secondary-border-radius;
  display: flex;
  align-items: center;
  gap: 0.31rem;

  &:hover {
    transition: background-color 0.5s ease;
    background: $main_clr_light;
  }
}

.user__dropdown-img {
  height: 12px;
  width: 12px;
}

.header__search {
  position: relative;

  &:before {
    position: absolute;
    left: 12px;
    top: 10px;
    width: 20px;
    height: 20px;
    content: '';
    background-image: url("../assets/icon/search.svg");
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.header__search-input {
  padding-left: 38px;
}

.container__full {
  margin-left: 114px;
}

.table__container {
  position: relative;
  background: $primary_clr;
  width: 100%;
  @extend .main-border-radius;
  //height: 100%;
  @include main-box-shadow;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.table__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  height: 90px;
  flex-shrink: 0;
}

.table__header_multiply {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  min-height: 90px;
}

.table__body {
  display: flex;
  //height: calc(100% - 90px);
  box-shadow: rgb(103 116 142 / 8%) 0 0 1rem 0;
  @extend .main-border-radius;
  overflow: hidden;
  height: 100%;
}

.table__edit {
  .ag-row {
    cursor: pointer;
  }
}

.table__with_pagination {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: width 0.5s ease;
}

.table__with_pagination-hidden {
  width: 0;
  overflow: hidden;
  transition: width 0.5s ease;
}

.table__title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  @include font(1rem, 600, $clr_2);
}

.documents__container {
}

.contract__container {
}

.deeds__container {
}

.deeds__body {
  overflow: hidden;
  border-radius: 0.625rem;
}

.price-plan__component {
  display: flex;
  flex-direction: column;
  width: 100%;

  .table__container {
    // 100% without tabs height + margin
    height: calc(100% - 61px);
  }
}

.requisites__component {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  overflow-y: auto;
}

.requisites__items-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.requisites__table {
  min-width: 300px;
  display: flex;
  flex: 1;
}

.requisites__item {
  @include font(0.875rem, 400, $clr_4);

  &:first-child {
    @include font(1rem, 400, $clr_2);
  }
}

/* PROFILE */
.profile__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: transparent;
}

.profile__header {
  padding: 1rem;
  background: $primary_clr;
  @extend .main-border-radius;
}

.profile__info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
}

.profile__balance {
  @include font(1.25rem, 400, $clr_1);
}

.profile__title {
  @include font(1rem, 600, $clr_1);
}

.profile__body {
  padding: 1rem;
  background: $primary_clr;
  @extend .main-border-radius;
  overflow-y: auto;
  height: 100%;
}

.qr-code__result {
  border: 1px solid $separator;
  @extend .secondary-border-radius;
  padding: 1rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.qr-code__title {
  @include font(1rem, 400, $clr_2);
}

.qr-code__description {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.qr-code__text {
  @include font(0.9375rem, 400, $clr_2);
}

.qr-code__img {
  svg {
    width: 200px;
    height: 200px;
  }
}

////////////////////
@media screen and (max-width: 992px) {
  .wrapper {
    padding: 10px;
  }

  .navigation {
    max-height: calc(100% - 20px);
    position: absolute;
    left: 10px;
  }

  .close-menu {
    display: flex;
    position: absolute;
    cursor: pointer;
    top: 43px;
    right: -20px;
    background: $primary_clr;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    @include main-box-shadow;
  }

  .navigation_desktop {
    display: none;
  }

  .navigation_mobile {
    display: flex;
  }

  .navigation__hidden {
    left: -100%;
  }
  .container__full,
  .container {
    margin-left: 0;
  }

  .header {
    justify-content: flex-end;
  }

  .icon-menu-desktop {
    display: none;
  }

  .icon-menu-mobile {
    width: 18px;
    height: 18px;
    display: block;
  }
}

//---------AG-GRID----------//
.ag-theme-alpine {
  overflow: hidden;
  transition: width 0.5s ease;

  .ag-paging-panel {
    display: none;
  }
}

.ag-root-wrapper {
  border: none !important;
}

.ag-header {
  background-color: $primary_clr !important;
  border-bottom-color: $separator !important;
}

.ag-header-cell-comp-wrapper {
  @include font(0.65rem, 700, $clr_3);
  text-transform: uppercase;
}

.ag-no-row-title {
  @include font(0.875rem, 500, $clr_3);
}

.ag-theme-alpine .ag-row-odd {
  background-color: $primary_clr !important;
}

.ag-row {
  @include font(0.75rem, 500, $clr_3);
  color: $clr_3 !important;
}

.ag-row-hover {
  background: $main_clr_light !important;
}

.ag-cell-focus {
  border: none !important;
}

.ag-cell {
  display: flex;
  align-items: center;
}

.cell-center-alignment {
  display: flex;
  justify-content: center;
}

.enter__container {
  display: flex;
  align-items: center;
}

.pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;
  border-top: 1px solid $separator;
  @include font(1rem, 500, $clr_1);
}

.pagination__input-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pagination__input {
  min-width: 40px;
  width: 70px;
  height: 30px;
}

.pagination__arrow-container {
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    @include main-box-shadow;
    border-radius: 50%;
  }
}

.pagination__select-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  min-width: 240px;

  .select {
    width: 90px;
  }
}

.table__bottom-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid $separator;

  .table__bottom-item {
    display: flex;
    align-items: center;
    @include font(1rem, 500, $clr_2);
    margin-left: 1rem;
  }

  .table__bottom-title {
    opacity: 0.6;
  }

  .table__bottom-description {
    margin-left: 0.5rem;
  }
}

.scan__visible {
  display: flex;
  justify-content: center;
  @extend .secondary-border-radius;
  overflow: hidden;

  #reader {
    width: 700px !important;
    max-height: 100%;
  }
}

.access-camera {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  img {
    width: 200px;
    height: 200px;
  }

  .access-camera__title {
    @include font(0.875rem, 700, $clr_3);
  }
}

.w-100 {
  width: 100%;
}

.wh-100 {
  width: 100%;
  height: 100%;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-0-5 {
  margin-top: 0.5rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-1-5 {
  margin-top: 1.5rem;
}

.alarm-text {
  color: $alarm_clr;
}
