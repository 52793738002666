@import "../../css/color";

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: $main_clr;
  z-index: 1001;
}

.triple-spinner {
  display: block;
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-top: 4px solid $main_clr_dark;
  animation: spin 1.25s linear infinite;
  top: 50%;
  left: 50%;
  margin-top: -75px;
  margin-left: -75px;
}

.triple-spinner::before,
.triple-spinner::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;

}

.triple-spinner::before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: $main_dark_clr;
  animation: spin 2.5s linear infinite;
}

.triple-spinner::after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-top-color: $primary_clr;
  animation: spin 1s linear infinite;
}
