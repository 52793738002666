@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');
@import "src/css/color";

$font-family_montserrat:   "Open Sans", Helvetica, Arial, sans-serif;

@mixin font($font_size:1rem, $font_weight:400, $font_color: $main_clr) {
  font-size: $font_size;
  font-family: $font-family_montserrat;
  font-weight: $font_weight;
  color: $font_color;
}
